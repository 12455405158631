import React, {useState} from 'react';

import {useHistory} from 'react-router-dom';
import {Box, Text, Flex, Link} from 'rebass';

import {gray} from '@renofi/utils/src/colors';
import {Checkbox, CheckboxLabel, Label} from '@renofi/components';

import {Heading, Footer} from '../components';
import {BASE_PATH} from '../const';

const NewAddress = () => {
  const [consent, setConsent] = useState(true);
  const history = useHistory();

  const onNext = () => {
    history.push(`${BASE_PATH}/score`);
  };

  const onBack = () => {
    history.push(`${BASE_PATH}/score`);
  };

  return (
    <>
      <Box pt={[0, 50]} pb={[0, 0]}>
        <Heading mt={[28, 13]} mb={[15, 21]} textAlign="left">
          Help us find your credit file by providing your address
        </Heading>
        <Flex mt={24} flexDirection="column" textAlign="left" color={gray}>
          <Flex py="6px">
            <Text width={1 / 3}>Name</Text>
            <Text>Jane Doe</Text>
          </Flex>
          <Flex py="6px">
            <Text width={1 / 3}>Date of birth</Text>
            <Text>10/20/1982</Text>
          </Flex>
          {/* TODO  Address field here */}
          <Text my={18} fontSize={16} lineHeight="24px">
            I ﻿understand that this is an eligibility inquiry for credit and
            authorize RenoFi to obtain credit information from a credit
            reporting agency for purposes of this eligibility inquiry.
            <Text mt={12}>
              I’ve also read and agreed to{' '}
              <Link
                fontSize={16}
                fontWeight="700"
                target="_blank"
                href="https://www.renofi.com/notices/legal/">
                RenoFi's terms of service.
              </Link>
            </Text>
          </Text>
          <Flex
            alignItems="center"
            css={{border: '2px solid #D8D8D8', padding: 12, borderRadius: 4}}
            mb={32}>
            <Label css={{cursor: 'pointer'}}>
              <Box minWidth={32}>
                <Checkbox
                  checked={consent}
                  id="consent"
                  name="consent"
                  onChange={() => setConsent(!consent)}
                />
              </Box>
              <CheckboxLabel>I agree and consent to these terms.</CheckboxLabel>
            </Label>
          </Flex>
        </Flex>
      </Box>
      <Footer
        showBack
        showNext
        disabled={!consent}
        onNext={onNext}
        onBack={onBack}
      />
    </>
  );
};

export default NewAddress;
